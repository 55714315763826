import Banner from "./Banner";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ContactOverlay from "./ContactOverlay";
import Sections from "./Sections";

export function App () {
    return (
        <div className="app">
            <Sidebar></Sidebar>
            <div className="top">
                <Header></Header>
                <Banner></Banner>
                <ContactOverlay></ContactOverlay>
            </div>
            <Sections></Sections>
        </div>
    );
}