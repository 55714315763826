import React from "react";
import About from "./About";
import Reviews from "./Reviews";
import './sections.css'

class Sections extends React.Component {
    render() {
        return (
            <div className="sections">
                <About></About>
                <Reviews></Reviews>
            </div>
        );
    }
}

export default Sections;