import React from 'react'
import './header.css'
import logo from '../../images/logo.png'

class Header extends React.Component {
    showSettings(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div className='header'>
                <img src={logo} />
            </div>
          );
    }
}

export default Header;