import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { faHouseChimney, faCircleInfo, faAddressBook, faRankingStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './sidebar.css'

class Sidebar extends React.Component {
    render() {
        return (
        <Menu right width={ '20%' } pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
                <a id="home" className="menu-item" href="/"><FontAwesomeIcon icon={faHouseChimney}/><span>Home</span></a>
                <a id="about" className="menu-item" href="/about"><FontAwesomeIcon icon={faCircleInfo}/><span>About</span></a>
                <a id="contact" className="menu-item" href="/contact"><FontAwesomeIcon icon={faAddressBook}/><span>Contact</span></a>
                <a id="reviews" href=""><FontAwesomeIcon icon={faRankingStar}/><span>Reviews</span></a>
            </Menu>  
        );
    }
}

export default Sidebar