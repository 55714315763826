import React from "react";
import './reviews.css'

class Reviews extends React.Component {
    render() {
        return (
            <div>
                <div className="text">
                    <div className="review">
                        "I organised my son's birthday party with Penelope. The day was fantastic and ran smoothly from start to finish. My son loved everything 
                        about it and I didn't have to lift a finger! I will 100% be using this service again.""
                    </div>
                    <div className="reviewer">
                        - Someone's Mum, Peterborough
                    </div>
                </div>
                <div className="text">
                    <div className="review">
                        "We at CompanyX used Penelope to organise our annual corporate function. We could not be happier with the service we received and how the day 
                        went for our staff members. Everything was so seamless! The communication with the team at Penelope was top notch and professional at all times.
                        We will absolutely be back next year!"
                    </div>
                    <div className="reviewer">
                        - Dave Davison, CEO of CompanyX
                    </div>
                </div>
            </div>
        );
    }
}

export default Reviews;