import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './contact-overlay.css'

class ContactOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            submitted: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value
        })
    }

    async handleSubmit(event) {
        event.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
        };

        await fetch("https://api.penelopeevents.co.uk/intro", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        this.setState({
            submitted: true,
        });
    }

    render() {
        return (
            !this.state.submitted
            ? (
                <div className="overlay">
                    <h2>Your event organised. <br />
                        Stress free.</h2>
                    <ul>
                        <li><FontAwesomeIcon icon={faCheck} /> Your event organised from start to finish</li>
                        <li><FontAwesomeIcon icon={faCheck} /> Flexibility at every step</li>
                        <li><FontAwesomeIcon icon={faCheck} /> Personal service, all day, every day</li>
                    </ul>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <input type="text" placeholder="Your name" name="name" value={this.state.name} onChange={this.handleChange} />
                        </div>
                        <div className="row">
                            <input type="text" placeholder="Your e-mail address" name="email" value={this.state.email} onChange={this.handleChange} />
                        </div>
                        <div className="row">
                            <input type="submit" value="Get Organised" />
                        </div>
                    </form>
                </div>
            )
            : (
                <div className="overlay">
                    <h2>Thank you, {this.state.name}!</h2>
                    <p>
                        We'll be in touch soon to gather more details about what you need and how we can help you.
                    </p>
                </div>
            )
        );
    }
}

export default ContactOverlay;