import React from 'react'
import './banner.css'

class Banner extends React.Component {
    render() {
        return (
            <div id="banner">
                <img src="https://static.independent.co.uk/s3fs-public/thumbnails/image/2019/08/14/16/istock-945885714.jpg" />
            </div>
        );
    }
}

export default Banner;