import React from "react";
import about from '../../../images/about.png'

class About extends React.Component {
    render() {
        return (
            <div>
                <div className="text">
                    <h2>Any event, of any type, of any size.</h2>
                    <p>
                        Our expert event organisers will ensure your day is planned to perfection so that you can just enjoy it.
                    </p>
                    <p>
                        Whether its a child's birthday, something for the holidays, your corporate events, or your wedding, we will take the stress of organising it
                        away from you while still keeping you in the loop at all times.
                    </p>
                </div>
                <div>
                    <img src={about} />
                </div>
            </div>
        );
    }
}

export default About;